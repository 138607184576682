import axios from 'axios';

async function login(user_name, password) {
  try {
    return await axios.post(
      process.env.REACT_APP_STAGE === 'production'
        ? `${process.env.REACT_APP_API_BASE_URL}/users/login-admin`
        : `${process.env.REACT_APP_LOCAL_API_URL}/users/login-admin`,
      {
        user_name,
        password,
      }
    );
  } catch (err) {
    throw err;
  }
}

export default login;
