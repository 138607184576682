import React from "react";
import ReactExport from "react-export-excel";
import CodeAttempts from "../api/codeAttempts";
import { Button } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportCodeAttempts extends React.Component {
  constructor(props) {
    super(props);

    this.sate = {
      codeAttempts: [],
      dateFrom: new Date(),
      dateTo: new Date(),
      searchText: "",
      searchBy: "all",
    };
  }

  async componentWillReceiveProps(props) {
    try {
      let dateFrom = new Date();
      dateFrom.setMonth(props.dateFrom.getUTCMonth());
      dateFrom.setFullYear(props.dateFrom.getUTCFullYear());
      dateFrom.setDate(props.dateFrom.getDate());
      dateFrom.setHours(0, 0, 0, 0);

      let dateTo = new Date();
      dateTo.setMonth(props.dateTo.getUTCMonth());
      dateTo.setFullYear(props.dateTo.getUTCFullYear());
      dateTo.setDate(props.dateTo.getDate());
      dateTo.setHours(23, 59, 59, 59);

      const result = await CodeAttempts.getForExport(
        dateFrom,
        dateTo,
        props.searchText,
        props.searchBy
      );
      this.setState({ codeAttempts: result.codeAttempts });
    } catch (error) {
      throw error;
    }
  }

  render() {
    return (
      <ExcelFile
        filename="Испратени кодови"
        element={
          <Button variant="btn mr-2 button-standard">Експортирај</Button>
        }
      >
        {this.state && this.state.codeAttempts != null ? (
          <ExcelSheet data={this.state.codeAttempts} name="codeAttempts">
            <ExcelColumn label="Телефон" value="phone_number" />
            <ExcelColumn label="Платформа" value="platform" />
            <ExcelColumn label="Одговор" value="response" />
            <ExcelColumn label="Испратен код" value="code_number" />
            <ExcelColumn label="Дата" value="sent" />
          </ExcelSheet>
        ) : null}
      </ExcelFile>
    );
  }
}
export default ExportCodeAttempts;
